<template lang="html">

  <section class="slider-full">
    <agile :options="myOptions" ref="carousel" @afterChange="newSlide($event)" @beforeChange="removeSlide($event)">

      <!--SLIDER 1-->
      <div class="slide ">
        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <h1 class="title-big -whiteC">
                {{ $t('home.slider.slider1') }}
              </h1>
            </Waypoint>
            <p class="description">
              {{ $t('home.slider.slider1_text') }}<br>{{ $t('home.slider.slider1_text2') }}</p>
            <router-link :to="{ name: 'demo' }" class="btn-slider dos">{{ $t('home.slider.btn-demo-aqui') }}
            </router-link>
          </div>
          <div class="div-image sliderprimera slider1">
            <img class=" primera" src="/img/sliderHome/slider1.svg" alt="slider home">
          </div>
        </div>
      </div>
      <!--FIN SLIDER 1-->

      <!--SLIDER 2-->
      <div class="slide ">
        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <p class="title-big -whiteC">
                {{ $t('home.slider.slider2') }}
              </p>
            </Waypoint>
            <router-link :to="{ name: 'demo' }" class="btn-slider dos">{{ $t('home.slider.btn-demo-aqui') }}
            </router-link>
          </div>
          <div class="div-image slider2 img1">
            <img alt="icono slider" :src='"/img/sliderHome/slider21-" + $i18n.locale + ".svg"'>
          </div>
          <div class="div-image slider2 img2">
            <img alt="icono slider" :src='"/img/sliderHome/slider22-" + $i18n.locale + ".svg"'>
          </div>
          <div class="div-image slider2 img3">
            <img alt="icono slider" :src='"/img/sliderHome/slider23-" + $i18n.locale + ".svg"'>
          </div>
        </div>
      </div>
      <div class="slide ">
        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <p class="title-big -whiteC">
                {{ $t('home.slider.slider3') }}
              </p>
            </Waypoint>
            <router-link :to="{ name: 'demo' }" class="btn-slider dos">{{ $t('home.slider.btn-demo-aqui') }}
            </router-link>
          </div>
          <div class="div-image slider2 img1">
            <img alt="icono slider" :src='"/img/sliderHome/slider31-" + $i18n.locale + ".svg"'>
          </div>
          <div class="div-image slider2 img2">
            <img alt="icono slider" :src='"/img/sliderHome/slider32-" + $i18n.locale + ".svg"'>
          </div>
          <div class="div-image slider2 img3">
            <img alt="icono slider" :src='"/img/sliderHome/slider33-" + $i18n.locale + ".svg"'>
          </div>
        </div>
      </div>
      <div class="slide ">
        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <p class="title-big -whiteC">

                {{ $t('home.slider.slider4') }}
              </p>
            </Waypoint>

            <router-link :to="{ name: 'demo' }" class="btn-slider dos">{{ $t('home.slider.btn-demo-aqui') }}
            </router-link>
          </div>
          <div class="div-image slider1">
            <img alt="icono slider" class="imgslider1 slider4" src="/img/sliderHome/slider4.svg">
          </div>
        </div>
      </div>
    </agile>
    <div class="popup-video" v-if="activePopup">
      <div class="container">
        <button class="close-pop-up" @click="activePopup = false">
          x
        </button>
        <div class="iframe" v-if="activeVideo">
          <YouTube :src="$t('home.url_video')" @ready="onReady" ref="youtube" width="100%" height="100%" @state-change="onPlayerStateChange" />
        </div>
        <template v-else>
          <div class="container-ask-demo animate__animated animate__fadeIn animate__slow">
            <img src="/img/icons/ask demo.svg" alt="Ask Demo">
            <router-link :to="{ name: 'contact' }" class="btn-blue">
              {{ $t('home.slider.ask_us') }}
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </section>


</template>

<script lang="js">
import 'vue3-lottie/dist/style.css'
import { Waypoint } from 'vue-waypoint'
import YouTube from 'vue3-youtube'

import {
  VueAgile
} from "vue-agile";

import homeIcons from '@/lotties/home.json'

export default {
  name: 'slider-full',
  props: [],
  components: {
    agile: VueAgile,
    Waypoint,
    YouTube,
  },
  data() {
    return {
      activePopup: false,
      activeVideo: true,
      homeIcons,
      myOptions: {
        responsive: [{
          breakpoint: 0,
          settings: {
            autoplay: true,
            fade: true,
            navButtons: false,
            slidesToShow: 1,
            dots: true,
            speed: 600,
          },
        }],

      }
    }
  },
  methods: {
    onReady() {
      this.$refs.youtube.playVideo()
    },
    onPlayerStateChange(event) {
      if (event.data === 0) {
        this.activeVideo = false
      }
    },
    newSlide($event) {
      if ($event.currentSlide == 1) {
        this.animationSlide1 = true
      }
      if ($event.currentSlide == 2) {
        this.animationSlide2 = true
      }
      if ($event.currentSlide == 3) {
        this.animationSlide3 = true
      }
    },
    removeSlide($event) {
      this.animationSlide1 = false
      this.animationSlide2 = false
      this.animationSlide3 = false
    }
  }
}

</script>

<style scoped lang="scss">
@media screen and (max-width:600px) {
  .slider-full .slide {
    min-height: 650px;
  }
}


.imgslider1.slider4,
.imgslider1 {
  width: 400px;
}

.btn-slider.video {
  margin-left: 20px
}

.div-image.slider2 img {
  width: 150px !important;
}

.img2 {
  margin-top: 100px
}

.slider-full .slide .container-slide .div-image {
  width: 17%;
}
.slider-full .slide .container-slide .div-image.sliderprimera {
    width: 35%;
}

.div-image.slider2.img1 {
  padding-bottom: 107px;
  padding-left: 193px;
}

.div-image.slider2.img2 {
  padding-top: 75px;
}

.div-image.slider2.img3 {
  margin-left: -123px;
}

.imgslider1 {
  width: 440px;
  margin-left: -210px;
}


.imgslider1.slider4,
.imgslider1 {
  width: 350px !important;
}

.slider1 .imgslider1.slider1 {
  width: 450px !important;
}

img.primera {
  width: 450px !important;
  margin-left:-50px;
}

@media screen and (max-width:1070px) {
  .div-image.slider2.img1 {
    padding-bottom: 107px;
    padding-left: 81px;
  }

  .div-image.slider2.img2 {
    margin-left: -107px;
    padding-top: 111px;
  }
}



@media screen and (max-width:880px) {
  .description {
    text-align: center;
  }

  p {
    text-align: center;
  }

  .going-in p.title-big {
    text-align: center;
  }

  .slider-full .slide .container-slide .div-text {
    width: 100%;
    margin-top: 50px;

  }

  .div-text .going-in {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .div-image {
    margin-bottom: 90px;
  }

  .slider-full .slide .container-slide .div-text .title-big {
    font-size: 25px;
    text-align: center;
  }

  .imgslider1.slider4,
  .imgslider1 {
    width: 270px !important;
  }

  .about-us .slider-full .slide .container-slide .div-image,
  .slider-full .slide .container-slide .div-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 200px;
  }

  .slider-full .slide .container-slide .div-image.slider2 {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 0px;
    margin-top: 6px;

  }

  .div-image.slider2.img3 {
    margin-top: -15px;
  }

  .btn-slider.dos {

    position: absolute;
    bottom: 35px;
  }
}

@media screen and (max-width:788px) {
  .slider-full .slide .container-slide .div-image.sliderprimera img.primera {
    width: 350px !important;
    margin-left: -192px;
    margin-bottom:50px;
}
  .div-image.slider2 img {
    width: 130px !important;
  }

  .div-image.slider2.img1,
  .div-image.slider2.img2,
  .div-image.slider2.img3 {
    padding: 0px;
    margin: 0px;
    margin-top: 35px
  }

}


@media screen and (max-width:655px) {}

@media screen and (max-width:390px) {
  .slider-full .slide .container-slide .div-image {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .slider-full .slide .container-slide .div-image:last-child {
    margin-bottom: 30px
  }
}
@media screen and (max-width:380px){
  .slider-full .slide .container-slide .div-image.sliderprimera img.primera {
    width: 350px !important;
    margin-left: -192px;
    margin-bottom:100px;
}
}
.container-slide{
  display: flex;
    align-items: flex-start;
}
</style>