<template lang="html">
  <section class="home">
    <sliderFull></sliderFull>
    <section class="logistic">
      <Waypoint effect="fadeIn">
        <div class="container d-flex aic jcc">

          <div class="div-img">
            <div class="preview-video" @click="activeVideo" v-show="maskVideo == true"></div>
            <YouTube src="https://www.youtube.com/embed/hLVTZR-TdZ8" v-if="$i18n.locale == 'es'" ref="youtube" width="100%" height="100%" />
            <YouTube src="https://www.youtube.com/embed/cRokZag3CIw" v-if="$i18n.locale == 'en'" ref="youtube" width="100%" height="100%" />
            <YouTube src="https://www.youtube.com/embed/cRokZag3CIw" v-if="$i18n.locale == 'it'" ref="youtube" width="100%" height="100%" />
            <YouTube src="https://www.youtube.com/embed/cRokZag3CIw" v-if="$i18n.locale == 'de'" ref="youtube" width="100%" height="100%" />
            <YouTube src="https://www.youtube.com/embed/cRokZag3CIw" v-if="$i18n.locale == 'nl'" ref="youtube" width="100%" height="100%" />
            <YouTube src="https://www.youtube.com/embed/cRokZag3CIw" v-if="$i18n.locale == 'fr'" ref="youtube" width="100%" height="100%" />

          </div>
          <div class="content ">
            <h2 class="title-small -blueC">{{ $t('home.intro') }}</h2>
            <h2 class="title-medium -blueDarkC">{{ $t('home.texto2') }}</h2>
            <p class="-blueDarkC ">
              {{ $t('home.texto3') }}
            </p>
          </div>
        </div>
      </Waypoint>
    </section>
    <section class="automatically-box">
      <Waypoint @change="onChange" id="line">
        <div class="detail1">
          <Vue3Lottie v-if="animationState.line" :animationData="LineBlue" :height="auto" width="100%" direction="forward" />
        </div>
      </Waypoint>
      <Waypoint @change="onChange" id="line2">
        <div class="detail2">
          <Vue3Lottie v-if="animationState.line2" :animationData="LineBlue2" :height="auto" width="100%" direction="forward" />
        </div>
      </Waypoint>
      <div class="container d-flex aic jcc movil container-gray">
        <div class="content">
          <h3 class="title-small -blueC"> {{ $t('home.component3.intro') }}</h3>
          <h2 class="title-extra-big -blueDarkC">{{ $t('home.component3.texto1') }}</h2>
        </div>
        <div class="box-info d-flex jcsb">
          <div class="grid">
            <Waypoint effect="fadeIn">
              <div class="box">
                <img src="/img/icons/icon-mobile.svg" alt="Icono movil">
                <p class="-blueDarkC title-semi-medium">{{ $t('home.component3.cuadro1') }}
                </p>
              </div>
            </Waypoint>
            <Waypoint effect="fadeIn">
              <div class="box">
                <img src="/img/icons/icon-inventory-management.svg" alt="icono inventory management">
                <p class="-blueDarkC title-semi-medium">{{ $t('home.component3.cuadro2') }}</p>
              </div>
            </Waypoint>
          </div>
          <div class="grid">
            <Waypoint effect="fadeIn">
              <div class="box">
                <img src="/img/icons/icon-calendary.svg" alt="icono calendario">
                <p class="-blueDarkC title-semi-medium">{{ $t('home.component3.cuadro3') }}</p>
              </div>
            </Waypoint>
            <Waypoint effect="fadeIn">
              <div class="box">
                <img src="/img/icons/icon-fleet-daily.svg" alt="cono fleet daily">
                <p class="-blueDarkC title-semi-medium">{{ $t('home.component3.cuadro4') }}</p>
              </div>
            </Waypoint>
          </div>
        </div>
      </div>
    </section>
    <section class="excel-sheet logistic">
      <Waypoint effect="fadeIn">
        <div class="container d-flex aic jcc">

          <div class="div-img">



            <img :src="`/img/illustrations/home3.png`" alt="ilustración">



          </div>
          <div class="content">
            <h3 class="title-small -blueC">{{ $t('home.component4.intro') }}</h3>
            <h2 class="title-medium -blueDarkC">{{ $t('home.component4.texto1') }}</h2>
            <p class="-blueDarkC ">
              {{ $t('home.component4.texto2') }}
            </p>
          </div>

        </div>
      </Waypoint>
    </section>
    <reserva></reserva>
    <section class="human-resource">
      <div class="container d-flex column-center">
        <h2 class="title-big -blueDarkC">{{ $t("home.ruletas.intro1") }}</h2>
        <Waypoint @change="onChange" id="graph1">
          <Vue3Lottie v-if="animationState.graph1" :animationData="grafica[$i18n.locale][1]" :height="auto" width="100%" direction="forward" :loop='1' />
        </Waypoint>
      </div>
    </section>


    <section class="fleet-management">
      <div class="container d-flex column-center">
        <h2 class="title-big -blueDarkC">{{ $t("home.ruletas.intro2") }}</h2>
        <Waypoint @change="onChange" id="graph2">
          <Vue3Lottie v-if="animationState.graph2" :animationData="grafica[$i18n.locale][2]" :height="auto" width="100%" direction="forward" :loop='1' />
        </Waypoint>
      </div>
    </section>

    <section class="human-resource-responsive">
      <div class="container d-flex column-center">
        <p class="title-big -blueDarkC">{{ $t("home.ruletas.intro1") }}</p>
        <div class="grafico-360">
          <div :class="$t('home.texto_360')">
            <p>{{ $t('home.grafico1.360') }}<br>{{ $t('home.grafico1.human') }}<br>{{ $t('home.grafico1.resource') }}
            </p>
          </div>
          <div>
            <img src="/img/hhrr-responsive.svg" alt="icono hhrr">
          </div>
        </div>
        <div class="hhrr-persona">
          <img src="/img/persona-360.svg" alt="icono persona">
        </div>

        <div class="listado-hhrr">
          <div class="li-hhrr">
            <div class="num azul"><img src='/img/hhrr-listado/1.svg' alt="listado numero 1"></div>
            <div>
              <p>{{ $t('home.grafico1.equipo') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num celeste"><img src='/img/hhrr-listado/2.svg' alt="listado numero 2"></div>
            <div>
              <p>{{ $t('home.grafico1.vacaciones') }}</p>
            </div>
          </div>

          <div class="li-hhrr">
            <div class="num oscuro"><img src='/img/hhrr-listado/3.svg' alt="listado numero 3"></div>
            <div>
              <p>{{ $t('home.grafico1.historial') }}</p>
            </div>
          </div>



          <div class="li-hhrr">
            <div class="num gris"><img src='/img/hhrr-listado/4.svg' alt="listado numero 4"></div>
            <div>
              <p>{{ $t('home.grafico1.horario') }}</p>
            </div>
          </div>

          <div class="listado-doble">
            <div class="li-hhrr">
              <div class="num azul"><img src='/img/hhrr-listado/5.svg' alt="listado numero 5">
              </div>
              <div>
                <p>{{ $t('home.grafico1.hoja') }}</p>
              </div>
            </div>

            <div class="sublistado">
              <img src="/img/hhrr-listado/flecha-clara.svg" alt="flecha">
              <p>{{ $t('home.grafico1.gestion-paquetes') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num celeste"><img src='/img/hhrr-listado/6.svg' alt="listado numero 6"></div>
            <div>
              <p>{{ $t('home.grafico1.vistazo-vacaciones') }}</p>
            </div>
          </div>

          <div class="li-hhrr">
            <div class="num oscuro"><img src='/img/hhrr-listado/7.svg' alt="listado numero 7"></div>
            <div>
              <p>{{ $t('home.grafico1.info-ruta') }}</p>
            </div>
          </div>


          <div class="listado-doble">
            <div class="li-hhrr">
              <div class="num gris"><img src='/img/hhrr-listado/8.svg' alt="listado numero 8">
              </div>
              <div>
                <p>{{ $t('home.grafico1.gastos-empleado') }}</p>
              </div>
            </div>

            <div class="sublistado">
              <img src="/img/hhrr-listado/flecha-oscura.svg" alt="flecha-oscura">
              <p class="gris">{{ $t('home.grafico1.gestion-incentivos') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num azul"><img src='/img/hhrr-listado/9.svg' alt="listado numero 9"></div>
            <div>
              <p>{{ $t('home.grafico1.accidentes-empleado') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num celeste"><img src='/img/hhrr-listado/10.svg' alt="listado numero 10"></div>
            <div>
              <p>{{ $t('home.grafico1.uniformes') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num gris"><img src='/img/hhrr-listado/11.svg' alt="listado numero 11"></div>
            <div>
              <p>{{ $t('home.grafico1.activos-empleado') }}</p>
            </div>
          </div>
        </div>
      </div>

    </section>




    <section class="human-resource-responsive fleet">
      <div class="container d-flex column-center">
        <p class="title-big -blueDarkC">{{ $t("home.ruletas.intro2") }}</p>
        <div class="grafico-360">
          <div :class="$t('home.texto_360')">
            <p>{{ $t('home.grafico1.360') }}<br>{{ $t('home.grafico2.field') }}</p>
          </div>
          <div>
            <img src="/img/hhrr-responsive.svg" alt="icono hhrr">
          </div>
        </div>
        <div class="hhrr-persona">
          <img src="/img/camion-360.svg">
        </div>

        <div class="listado-hhrr">
          <div class="li-hhrr">
            <div class="num azul"><img src='/img/hhrr-listado/1.svg' alt="listado numero 1"></div>
            <div>
              <p>{{ $t('home.grafico2.1') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num celeste"><img src='/img/hhrr-listado/2.svg' alt="listado numero 2"></div>
            <div>
              <p>{{ $t('home.grafico2.2') }}</p>
            </div>
          </div>

          <div class="li-hhrr">
            <div class="num oscuro"><img src='/img/hhrr-listado/3.svg' alt="listado numero 3"></div>
            <div>
              <p>{{ $t('home.grafico2.3') }}</p>
            </div>
          </div>



          <div class="li-hhrr">
            <div class="num gris"><img src='/img/hhrr-listado/4.svg' alt="listado numero 4"></div>
            <div>
              <p>{{ $t('home.grafico2.4') }}</p>
            </div>
          </div>

          <div class="li-hhrr">
            <div class="num azul"><img src='/img/hhrr-listado/5.svg' alt="listado numero 5"></div>
            <div>
              <p>{{ $t('home.grafico2.5') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num celeste"><img src='/img/hhrr-listado/6.svg' alt="listado numero 6"></div>
            <div>
              <p>{{ $t('home.grafico2.6') }}</p>
            </div>
          </div>

          <div class="li-hhrr">
            <div class="num oscuro"><img src='/img/hhrr-listado/7.svg' alt="listado numero 7"></div>
            <div>
              <p>{{ $t('home.grafico2.7') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num gris"><img src='/img/hhrr-listado/8.svg' alt="listado numero 8"></div>
            <div>
              <p>{{ $t('home.grafico2.8') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num azul"><img src='/img/hhrr-listado/9.svg' alt="listado numero 9"></div>
            <div>
              <p>{{ $t('home.grafico2.9') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num celeste"><img src='/img/hhrr-listado/10.svg' alt="listado numero 10"></div>
            <div>
              <p>{{ $t('home.grafico2.10') }}</p>
            </div>
          </div>


          <div class="li-hhrr">
            <div class="num gris"><img src='/img/hhrr-listado/11.svg' alt="listado numero 11"></div>
            <div>
              <p>{{ $t('home.grafico2.11') }}</p>
            </div>
          </div>
        </div>
      </div>

    </section>
    <section class="automatically-box investor" v-show="$i18n.locale == 'en'">
      <Waypoint @change="onChange" id="line">
        <div class="detail1">
          <Vue3Lottie v-if="animationState.line" :animationData="LineBlue" :height="auto" width="100%" direction="forward" />
        </div>
      </Waypoint>
      <Waypoint @change="onChange" id="line2">
        <div class="detail2">
          <Vue3Lottie v-if="animationState.line2" :animationData="LineBlue2" :height="auto" width="100%" direction="forward" />
        </div>
      </Waypoint>
      <div class="container d-flex aic jcc movil container-gray">
        <div class="content">
          <h3 class="title-small -blueC"> {{ $t('home.component3.intro') }}</h3>
          <h2 class="title-extra-big -blueDarkC">{{ $t('home.component3.inversor') }}</h2>
          <a href="/en/investor" class="btn-destacado">Learn more</a>
        </div>
        <div class="box-info d-flex jcsb">
          <Waypoint effect="fadeIn">
            <img src="/img/illustrations/info-investor-home.svg" alt="Investors">
          </Waypoint>
        </div>
      </div>
    </section>


    <newsletter></newsletter>
  </section>
</template>

<script lang="js">
  import { Vue3Lottie } from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'

  import LineBlue from '@/lotties/line_1_blue.json'
  import LineBlue2 from '@/lotties/line_2_blue.json'

  import grafica1en from '@/lotties/grafica1-en.json'
  import grafica2en from '@/lotties/grafica2-en.json'
  import grafica1es from '@/lotties/grafica1-es.json'
  import grafica2es from '@/lotties/grafica2-es.json'
  import grafica1fr from '@/lotties/grafica1-fr.json'
  import grafica2fr from '@/lotties/grafica2-fr.json'
  import grafica1it from '@/lotties/grafica1-it.json'
  import grafica2it from '@/lotties/grafica2-it.json'
  import grafica1nl from '@/lotties/grafica1-nl.json'
  import grafica2nl from '@/lotties/grafica2-nl.json'
  import grafica1de from '@/lotties/grafica1-de.json'
  import grafica2de from '@/lotties/grafica2-de.json'

  import { Waypoint } from 'vue-waypoint'
  import sliderFull from '@/components/slider-full.vue'

  import newsletter from '@/components/newsletter'
  import reserva from '@/components/reserva'
  import YouTube from 'vue3-youtube'
  export default {
    name: 'home',
    props: [],
    components: {
      sliderFull,
      Waypoint,
      Vue3Lottie,
      YouTube,
      'newsletter': newsletter,
      'reserva': reserva
    },

    data() {

      return {
        animationState: {
          graph1: false,
          graph2: false,
          line: false,
          line2: false,
        },
        LineBlue,
        LineBlue2,
        grafica1en,
        grafica: {
          en: {
            1: grafica1en,
            2: grafica2en
          },
          es: {
            1: grafica1es,
            2: grafica2es,
          },
          de: {
            1: grafica1de,
            2: grafica2de
          },
          fr: {
            1: grafica1fr,
            2: grafica2fr
          },
          it: {
            1: grafica1it,
            2: grafica2it
          },
          nl: {
            1: grafica1nl,
            2: grafica2nl,
          }
        },
        maskVideo: true,
        videoOn: true
      }
    },

    methods: {
      onChange(waypointState) {
        if (this.animationState[waypointState.el.id] === false) {
          this.animationState[waypointState.el.id] = waypointState.going == 'IN'
        }
      },
      activeVideo() {
        this.maskVideo = false
        this.videoOn = true
        this.$refs.youtube.playVideo()

      },
      onPlayerStateChange(event) {
        console.log(event)
        if (event.data === 0) {
          this.maskVideo = true
        }
      }

    },
    watch: {
      "$i18n.locale"() {

        this.videoOn = false
        this.maskVideo = true
      },
      '$route'() {
        location.reload()

      }
    }
  }


</script>

<style scoped lang="scss">
  .excel-sheet .container {
    padding-bottom: 100px;

    & .div-img img {
      width: 90%
    }
  }

  .home {
    margin-top: 34px;
  }

  .boton-rsami {
    background-color: #0AA6DF;
    padding: 10px 15px;
    color: white;
    font-size: 14px;
    border-radius: 17px;
    text-transform: uppercase;
  }

  #graph1,
  #graph2 {
    min-height: 400px;
    height: 100%;
  }

  .human-resource-responsive {
    display: none;
  }

  .logistic .container .div-img {
    width: 45%
  }

  .logistic .container .content {
    padding-left: 80px;
  }

  .automatically-box .container-gray {
    max-width: 1242px;
  }


  @media screen and (max-width:900px) {
    .reserva-demo div div+div img {
      z-index: 0;
    }

    .human-resource-responsive {
      background-color: #E5E5E5;
      padding: 30px;
      display: block;
      & .grafico-360 {
        margin-top: 20px;

        & .texto-360 {
          position: absolute;
          z-index: 0;
          margin: 24px 29px;
          text-align: center;
          font-size: 14px;

          & p {
            font-size: 13px;
          }
        }

        & .it.texto-360 {
          margin: 24px 37px;
        }

        & .de.texto-360 {
          margin: 24px 19px;
        }
      }



      &+div {
        position: relative;
        z-index: -1;
      }

      & .listado-hhrr {
        width: 100%;
        max-width: 220px;

        & .li-hhrr {
          display: flex;
          justify-content: center;
          margin: 10px 0px;
          align-items: flex-start;

          & .num {
            padding: 8px;
            min-height: 31px;
            height: 100%;

            &+div {
              padding: 4px;
              color: white;
              min-width: 290px;
              padding-left: 16px;
              margin-left: 10px;

              & p {
                color: white;
                min-height: 33px;
              }
            }
          }

          & .num.azul,
          .num:nth-child(9) {
            background: #009ECD;

            &+div {
              background: #009ECD;
            }
          }

          & .num.celeste {
            background: #31D0CB;

            &+div {
              background: #31D0CB;
            }
          }

          & .num.oscuro {
            background: #405C72;

            &+div {
              background: #405C72;
            }
          }

          & .num.gris {
            background: #525455;

            &+div {
              background: #525455;
            }
          }

        }

        & .sublistado {
          min-width: 290px;

          & p {
            width: 100%;
            text-align: left;
            color: #0AA6DF;
            border: 1px solid #0AA6DF;
            padding: 10px 5px;
            font-size: 14px;
            margin-top: 5px;
            margin-left: -10px;
          }

          & p.gris {
            color: #525455;
            border: 1px solid #525455;
          }
        }
      }



    }

    .human-resource-responsive.fleet {
      background-color: white;

      & .grafico-360 .texto-360 {
        margin: 36px 42px;
      }

      & .grafico-360 .de.texto-360 {
        margin: 36px 32px;
      }
    }

    .human-resource,
    .fleet-management {
      display: none;
    }

    .title-big {
      text-align: center;
    }
  }

  @media screen and (max-width:700px) {

    .human-resource .container,
    .fleet-management .container {
      padding: 0px 5px;
    }
  }

  .reserva-demo .container .content {
    padding-left: 35px !important;
  }

  .excel-sheet .container .div-img {
    height: 100%
  }
</style>